<template>
  <el-card class="box-card full-screen business-handling account-info my-card" :body-style="cardBody">
    <el-page-header @back="$router.go(-1)">
      <template slot="content"><span style="font-size:14px">{{$t('accountInfo')}}</span></template>
    </el-page-header>
    <div class="container">
      <el-row :gutter="20" class="fixed-size">
        <el-col :span="12">
          <ul>
            <li>{{$t('lastName')}}：{{userInfo.surname||''}}</li>
            <li>{{$t('firstName')}}：{{userInfo.name||''}}</li>
            <li>{{$t('linkPhone')}}：{{userInfo.phoneNo||''}}</li>
            <li>{{$t('idCard')}}：{{userInfo.idCard||''}}</li>
          </ul>
        </el-col>
        <el-col :span="12">
          <ul>
            <li v-if="loginType.name=='merchantLogin'">{{$t('post')}}：{{userInfo.job || ''}}</li>
            <li v-else-if="loginType.name=='customerLogin'">{{$t('number')}}：{{userInfo.customerCode || ''}}</li>
            <li>{{$t('accountType')}}：{{$t(userInfo.type==1?'platform':userInfo.type==2?'directSaleStore':userInfo.type==3?'franchiseStore':'individualAccount')}}</li>
            <li>{{$t('loaginAccount')}}：{{userInfo.loginName || ''}}</li>
            <li>{{$t('status')}}：
              <span v-if="userInfo.status==0" class="text-success">{{$t('normal')}}</span>
              <span v-else class="text-wraning">{{$t('lock')}}</span>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>
<script>

import org from '@/api/org'
import customerBusiness from '@/api/customerBusiness'
import storage from '@/assets/uilt/storage';
export default {
  computed: {
    cardBody () { return this.$store.state.style.cardBody },
    countryList () { return this.$store.state.globalParameter.countryList; }
  },
  data () {
    return {
      orgInfo: {},
      userInfo: {},
      loginType: {},
    }
  },
  methods: {
    getOrgInfo () {
      org.getOrgInfo({
        success: res => {
          this.orgInfo = res.data;
        }
      })
    },
    getOrgUser () {
      org.getOrgUser({
        success: res => {
          this.userInfo = res.data;
        }
      })
    },
    getCustomerInfo () {
      customerBusiness.getCustomerInfo({
        success: res => {
          this.userInfo = res.data;
        }
      })
    },

    setRegion (id) {
      if (!id) return '';

      let c = this.countryList.find(function (t) { return t.id == id })
      return c.name || '';
    }
  },
  mounted () {
    let loginName = storage.local.get('loginName');
    this.$set(this, 'loginType', loginName)
    if (!loginName)
      this.$router.push({ name: 'merchantLogin' })
    else if (loginName.name === 'merchantLogin')
      this.getOrgUser();
    else if (loginName.name === 'customerLogin')
      this.getCustomerInfo();

    this.getOrgInfo();

  }
}
</script>
<style lang="less" scoped>
.account-info.business-handling {
  height: 100%;
  width: 100%;
  .el-card__body {
    position: relative;
    .container {
      position: absolute;
      top: 60px;
      bottom: 20px;
      left: 20px;
      right: 0;
      padding-right: 20px;
      overflow: auto;
      .fixed-size {
        width: 1080px;
        margin: auto !important;
        border: 1px;
        li {
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>